import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';

import { logoutAuth } from '../../auth';

import { logout } from '../../services/login';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Logout() {
    const navigate = useNavigate();
    const token = localStorage.getItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO');
    const sucursal = JSON.parse(localStorage.getItem('SUCURSAL_SCORPION_TICKETS_KIOSKO'));

    const salir = async () => {
        const result = await logout({numSucursal: sucursal.num_sucursal, headers:{"x-access-token": token, "plataforma": "kiosko"}});

        if (result.status) {
            logoutAuth();
            navigate('/', { replace: true });
        }
    }

    return (
        <>
            <Helmet>
                <title> Salir | Scorpion Kiosko </title>
            </Helmet>

            <Container>
                <StyledContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h3" paragraph style={{ color: 'white' }}>
                        Cerrar Sesión
                    </Typography>

                    <Typography sx={{ color: 'white', marginBottom: '50px' }}>
                        ¿Esta seguro que quiere cerrar sesión?
                    </Typography>

                    <Button size="large" variant="contained" onClick={salir} style={{ background: '#ea5317' }}>
                        Salir
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
}
