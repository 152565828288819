import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Box,
    Card,
    Stack,
    Typography,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { styled } from '@material-ui/core/styles';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@material-ui/lab';

// Pdf
import { PDFViewer } from '@react-pdf/renderer';
import PdfTicket from '../components/PdfTicket';

import { obtenerTicket, registrarRegistro } from '../../services/tickets';

// context
import useCliente from '../../hooks/useCliente';

// Imagen
import imagen from '../../assets/images/kiosko/Don Tono Portal.png';

// ----------------------------------------------------------------------

const LargeImgStyle = styled('img')({
    width: '400px',
    height: '400px',
    position: 'absolute',
    marginTop: '50px'
});

export default function Promociones() {
    const sucursal = JSON.parse(localStorage.getItem('SUCURSAL_SCORPION_TICKETS_KIOSKO'));
    const { cliente, onChangeCliente } = useCliente();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState({});
    const [animacionCarga, setAnimacionCarga] = useState(false);
    let timeout = null;

    useEffect(() => {
        getTicket();
    }, []);

    const goBackToHome = () => {
        navigate('/app/inicio', { replace: true });
    };

    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }

        // Establecer tiempo para recargar la página si hay inactividad en 30 segundos
        timeout = setTimeout(() => {
            goBackToHome();
        }, 30000);
    }
    
    const onMouseMove = () => {
        restartAutoReset();
    }
    
    useEffect(() => {
        // Iniciar tiempo
        restartAutoReset();
    
        // Escuchar mouse event
        window.addEventListener('mousemove', onMouseMove);
    
        // Limpiar
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                window.removeEventListener('mousemove', onMouseMove);
            }
        }
    }, []);

    const getTicket = async () => {
        setAnimacionCarga(true);

        const result = await obtenerTicket({ sucursal: sucursal.num_sucursal, cliente: cliente.cte, numeroTarjeta: cliente.id, nombreCliente: cliente.nom });

        if (result.status) {
            setTicket(result.data);
            setAnimacionCarga(false);
            return;
        }

        setAnimacionCarga(false);
        enqueueSnackbar(result.mensaje, {
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const imprimirTicket = async () => {
        const iframe = window.frames.pdf;
        const iframeWindow = iframe.contentWindow;

        console.log(ticket)

        setTimeout(() => {
            iframe.focus();
            iframeWindow.print();
        }, 500);

        // HACER REGISTRO EN LA BD
        await registrarRegistro({
            num_tarjeta: ticket.numeroTarjeta,
            num_cliente: ticket.numeroCliente,
            usuario: ticket.nombreCliente,
            num_sucursal: sucursal.num_sucursal,
            nombre_sucursal: ticket.sucursal 
        });

        // Llevar a la página principal después de imprimir el ticket de 10 segundos
        setTimeout(() => {
            onChangeCliente({});
            navigate('/app/inicio', { replace: true });
        }, 10000);
    }

    return (
        <div>
            <Helmet>
                <title> Ticket | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Container maxWidth="xl" style={{ marginTop: '50px' }}>
                <Box sx={{ display: 'flex', marginBottom: '25px', alignItems: 'baseline' }}>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>Imprime tu&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px' }}>TIRA&nbsp;</h1>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>de&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px', fontStyle: 'italic' }}>promociones</h1>
                </Box>
                <hr style={{ borderTop: '8px solid #fff', borderRadius: '5px', background: 'white', opacity: 'inherit' }}></hr>

                <Box sx={{ display: 'flex', alignItems: 'end', marginTop: '30px' }}>
                    <Breadcrumb className='px-3 py-2 bg-light rounded mb-3' style={{ width: '-webkit-fill-available', marginRight: '25px' }}>
                        <BreadcrumbItem><Link to="/app/inicio">Inicio</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Promociones</BreadcrumbItem>
                    </Breadcrumb>
                    <LoadingButton variant="contained" style={{ background: '#ea5317', marginBottom: '25px' }} startIcon={<PrintIcon />} onClick={imprimirTicket}>
                        Imprimir
                    </LoadingButton>
                </Box>

                <Grid item style={{ display: 'block', marginInline: 'auto', maxWidth: '100%' }}>
                    <Box>
                        <LargeImgStyle alt="Don Toño" src={imagen} />
                    </Box>
                </Grid>

                <Grid container spacing={3} mb={3} mt={1} style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        {
                            Object.keys(ticket).length !== 0 && (
                                <PDFViewer className='pdf__viewer' id='pdf' style={{ display: "none" }}>
                                    <PdfTicket
                                        ticketData={ticket}
                                    />
                                </PDFViewer>
                            )
                        }
                        
                        <Grid item xs={12} style={{ border: 'solid #fff', borderRadius: '20px', padding: '10px' }}>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4 }}>
                                <Typography variant="h4" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                                    Ticket de Promociones
                                </Typography>

                                <Stack>
                                    {Object.keys(ticket).length !== 0 && (
                                        <>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                NÚMERO TARJETA: {ticket.numeroTarjeta}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                NÚMERO CLIENTE: {ticket.numeroCliente}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.fecha}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.nombreCliente}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.nivelCliente}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                SUC: {ticket.sucursal}
                                            </Typography>
                                            <br />
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.tituloTicket.mensaje1}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.tituloTicket.mensaje2}
                                            </Typography>
                                            <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                {ticket.tituloTicket.mensaje3}
                                            </Typography>
                                            <br />
                                            {
                                                ticket.promocionesTicket.map((promocion, index) => (
                                                    <div key={index}>
                                                        <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                            {promocion.nombre}
                                                        </Typography>

                                                        {promocion.articulos.map((articulo, index) => (
                                                            <Typography key={index} variant='body1'>
                                                                {articulo}
                                                            </Typography>
                                                        ))}
                                                        <br />
                                                    </div>
                                                ))
                                            }
                                        </>
                                    )}
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}