import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, Link } from 'react-router-dom';
// @mui
import {
    Container,
    Backdrop,
    CircularProgress,
    Grid,
    Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// IMAGEN
import logo from '../../assets/images/logos/logo_scorpion.png';

// PDF
import { BlobProvider  } from '@react-pdf/renderer';
import { MobilePDFReader } from 'react-read-pdf';
import { PDFReader } from 'react-read-pdf';

import PdfTicket from '../components/PdfTicket';

// CONTEXT
import useTicket from '../../hooks/useTicket';

// SERVICES
import { obtenerTicketMicroSitio } from '../../services/microSitio';

export default function MicroSitio() {
    const { onChangeTicket } = useTicket();
    const [animacionCarga, setAnimacionCarga] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [ticket, setTicket] = useState({});


    useEffect(() => {
        obtenerTicket();
    }, []);

    const obtenerTicket = async () => {
        setAnimacionCarga(true);
        const codBarras = searchParams.get("codBarras");
        const sucursal = searchParams.get("sucursal");

        if (codBarras === null || sucursal === null) {
            enqueueSnackbar("URL erronea, intente con otra URL", { 
                variant: 'error',
                autoHideDuration: 10000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            setAnimacionCarga(false);
            return;
        }

        const result = await obtenerTicketMicroSitio({codBarras: codBarras, sucursal: sucursal});

        if (!result.status) {
            enqueueSnackbar(result.mensaje, { 
                variant: 'error',
                autoHideDuration: 10000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            setAnimacionCarga(false);
            return;
        }

        setTicket(result.data);
        onChangeTicket(result.data);
        setAnimacionCarga(false);
    }

    return (
        <div>
            <Helmet>
                <title> Micro Sitio | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                <Grid container justifyContent="center">
                    <img src={logo} alt="scorpion" height="140" />
                </Grid>

                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item>
                        {Object.keys(ticket).length !== 0 && (
                            <>
                                <Grid container justifyContent="end">
                                    <Button variant='contained' component={Link} to={'/micro-sitio/mas-promociones'} color="success" startIcon={<AddIcon />}>Beneficios</Button>
                                </Grid>
                                <Grid container justifyContent="center" mt={3}>

                                <BlobProvider document={<PdfTicket  ticketData={ticket} />}>
                                    {({ blob, url, loading, error }) => {
                                    // Do whatever you need with blob here
                                    console.log(loading)
                                    if(url != null) {
                                        return <div style={{overflow:'scroll',height:1500, position:'relative'}}>     
                                        <MobilePDFReader   url={url}/>
                                        </div>;
                                    }
                                    else{
                                        return <div>cargando</div>
                                    }

                                    }}
                                </BlobProvider>

                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
