import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// services
import { login } from '../../services/login';

import { loginAuth } from '../../auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        numSucursal: "",
        password: "",
    });
    const [loadingBoton, setLoadingBoton] = useState(false);

    const handleChangeText = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const ingresar = async () => {
        setLoadingBoton(true);
        const result = await login(form);
        setLoadingBoton(false);

        enqueueSnackbar(result.mensaje, {
            variant: result.status ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });

        if (result.status) {
            loginAuth(result.token, result.sucursal);
            setForm({});
            setLoadingBoton(false);
            navigate(`/app/inicio`, { replace: true });
        }
    };

    return (
        <>
            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Stack spacing={3} mt={5}>
                <TextField name="numSucursal" label="Número Sucursal" onChange={handleChangeText} />
                <TextField
                    name="password"
                    label={`Contraseña`}
                    type="password"
                    onChange={handleChangeText}
                />
            </Stack>

            <LoadingButton fullWidth size="large" style={{ marginTop: 25 }} variant="contained" loading={loadingBoton} onClick={ingresar}>
                Ingresar
            </LoadingButton>
        </>
    );
}
