import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PesosLealtadIcon() {
  return (
    <SvgIcon style={{ height: '150px', width: '150px'}}>
        <svg version="1.1" viewBox="0 0 1184 1184" width="1280" height="1280" xmlns="http://www.w3.org/2000/svg">
          <path transform="translate(584,118)" d="m0 0h12l32 2 30 4 25 5 28 8 27 10 23 10 22 12 18 11 16 11 17 13 14 12 12 11 18 18 7 8 9 11 10 13 13 19 12 20 14 27 11 26 10 30 6 24 5 27 3 29 1 15v26l-3 36-5 30-8 32-8 24-9 22-12 25-12 21-14 21-9 12-8 10-11 13-11 12-14 14-8 7-12 10-13 10-20 14-22 13-27 14-27 11-27 9-29 7-30 5-18 2-20 1h-33l-29-3-30-5-28-7-30-10-26-11-22-11-17-10-12-8-17-12-14-11-11-10-8-7-10-9-7-7-9-11-11-12-15-20-12-18-15-26-13-27-8-21-8-24-6-24-6-34-3-33v-34l3-33 5-29 6-26 12-36 10-23 8-16 12-22 10-15 10-14 10-13 9-11 12-13 23-23 22-18 18-13 19-12 18-10 16-8 17-8 28-10 24-7 22-5 26-4 22-2zm-9 105-21 2-24 4-26 7-19 7-20 9-17 9-14 9-14 10-10 8-13 12-21 21-11 14-9 12-12 19-12 23-9 23-7 22-4 17-4 26-1 13v36l3 24 5 25 6 21 9 24 8 17 12 21 11 16 13 16 9 10 21 21 14 11 15 11 20 12 19 10 20 8 25 8 28 6 27 3h35l21-2 23-4 23-6 20-7 20-9 21-11 21-14 13-10 14-12 22-22 8-10 12-16 11-18 10-18 11-26 8-26 6-28 2-17 1-19v-16l-2-28-4-23-6-24-7-20-11-25-11-20-12-18-10-13-12-14-13-13-12-11-17-13-13-9-17-10-15-8-24-10-25-8-29-6-28-3z" fill="#fff"/>
          <path transform="translate(989,935)" d="m0 0h3l1 91-2 3-39 7-46 7-51 6-53 5-57 4-94 4-37 1h-53l-109-4-47-3-41-3-49-5-49-6-57-9-19-4-2-2v-90l4-1 20 8 23 7 30 7 35 6 49 6 49 4 38 2 64 2h184l56-2 47-3 41-4 39-5 35-6 31-7 31-8z" fill="#fff"/>
          <path transform="translate(371,865)" d="m0 0h14l16 9 28 13 21 8 27 9 28 7 30 5 29 3h54l35-4 31-6 26-7 27-9 28-12 24-12 7-4h14l56 6 40 6 35 7 24 7 16 7 9 7 2 3v9l-5 6-16 8-19 6-30 7-28 5-37 5-47 5-56 4-56 3-34 1h-159l-31-1-55-3-71-6-56-7-39-7-25-6-21-7-11-6-5-4-3-5 1-7 6-7 15-8 26-8 28-6 44-7 43-5z" fill="#fff"/>
          <path transform="translate(592,351)" d="m0 0 4 2 7 16 19 41 14 30 9 20 118 14 3 2-11 9-10 9-11 9-10 9-11 9-11 10-11 9-13 11-4 4 3 20 17 94 1 6-6-2-28-17-30-18-21-13-15-9-6 1-19 12-17 10-26 16-20 12-13 8h-2l1-9 10-57 9-49v-6l-10-9-11-9-10-9-11-9-15-13-11-9-11-10-12-9v-2l10-2 103-12 7-1 7-15 18-38 12-26 8-18z" fill="#fff"/>
        </svg>
    </SvgIcon>
  );
}
