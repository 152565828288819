import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Box,
    Card,
    Stack,
    Typography,
    Backdrop,
    CircularProgress,
    ListItem,
    ListItemText,
} from '@mui/material';
import { styled } from '@material-ui/core/styles';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { obtenerTicket, registrarRegistro } from '../../services/tickets';

// context
import useCliente from '../../hooks/useCliente';

// Imagen
import imagen from '../../assets/images/kiosko/Don Tono Portal.png';

// ----------------------------------------------------------------------
const {
    REACT_APP_API_URL_IMAGES
} = process.env;

const LargeImgStyle = styled('img')({
    width: '400px',
    height: '400px',
    position: 'absolute',
    marginTop: '50px'
});

export default function PesosLealtad() {
    const sucursal = JSON.parse(localStorage.getItem('SUCURSAL_SCORPION_TICKETS_KIOSKO'));
    const { cliente, onChangeCliente } = useCliente();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState({});
    const [animacionCarga, setAnimacionCarga] = useState(false);
    // let timeout = null;

    useEffect(() => {
        getTicket();
    }, []);

    // const goBackToHome = () => {
    //     navigate('/app/inicio', { replace: true });
    // };

    // const restartAutoReset = () => {
    //     if (timeout) {
    //         clearTimeout(timeout);
    //     }

    //     // Establecer tiempo para recargar la página si hay inactividad en 30 segundos
    //     timeout = setTimeout(() => {
    //         goBackToHome();
    //     }, 30000);
    // }
    
    // const onMouseMove = () => {
    //     restartAutoReset();
    // }
    
    // useEffect(() => {
    //     // Iniciar tiempo
    //     restartAutoReset();
    
    //     // Escuchar mouse event
    //     window.addEventListener('mousemove', onMouseMove);
    
    //     // Limpiar
    //     return () => {
    //         if (timeout) {
    //             clearTimeout(timeout);
    //             window.removeEventListener('mousemove', onMouseMove);
    //         }
    //     }
    // }, []);

    const getTicket = async () => {
        setAnimacionCarga(true);

        const result = await obtenerTicket({ sucursal: sucursal.num_sucursal, cliente: cliente.cte, numeroTarjeta: cliente.id, nombreCliente: cliente.nom });

        if (result.status) {
            setTicket(result.data);
            setAnimacionCarga(false);
            return;
        }

        setAnimacionCarga(false);
        enqueueSnackbar(result.mensaje, {
            variant: result.status ? 'success' : 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const imprimirTicket = async () => {
        const iframe = window.frames.pdf;
        const iframeWindow = iframe.contentWindow;

        console.log(iframe);
        console.log(iframeWindow);
        setTimeout(() => {
            iframe.focus();
            iframeWindow.print();
        }, 500);

        // HACER REGISTRO EN LA BD
        await registrarRegistro({ num_tarjeta: ticket.numeroTarjeta, num_cliente: ticket.numeroCliente, usuario: ticket.nombreCliente, num_sucursal: ticket.numSucursal, nombre_sucursal: ticket.sucursal });

        // Llevar a la página principal después de imprimir el ticket de 10 segundos
        setTimeout(() => {
            onChangeCliente({});
            navigate('/app/inicio', { replace: true });
        }, 10000);
    }

    return (
        <div>
            <Helmet>
                <title>Pesos Lealtad | Scorpion</title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Container maxWidth="xl" style={{ marginTop: '50px' }}>
                <Box sx={{ display: 'flex', marginBottom: '25px', alignItems: 'baseline' }}>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>Observa tus&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px' }}>Pesos&nbsp;</h1>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>de&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px', fontStyle: 'italic' }}>lealtad</h1>
                </Box>
                <hr style={{ borderTop: '8px solid #fff', borderRadius: '5px', background: 'white', opacity: 'inherit' }}></hr>

                <Box sx={{ display: 'flex', alignItems: 'end', marginTop: '30px' }}>
                    <Breadcrumb className='px-3 py-2 bg-light rounded mb-3' style={{ width: '-webkit-fill-available', marginRight: '25px' }}>
                        <BreadcrumbItem><Link to="/app/inicio">Inicio</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Pesos Lealtad</BreadcrumbItem>
                    </Breadcrumb>
                </Box>

                <Grid item style={{ display: 'block', marginInline: 'auto', maxWidth: '100%' }}>
                    <Box>
                        <LargeImgStyle alt="Don Toño" src={imagen} />
                    </Box>
                </Grid>

                <Grid container spacing={3} mb={3} mt={1} style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} style={{ border: 'solid #fff', borderRadius: '20px', padding: '10px' }}>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4 }}>
                                <Typography variant="h4" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                                    Pesos de Lealtad
                                </Typography>

                                <Stack>
                                    {Object.keys(ticket).length !== 0 && (
                                        <>
                                            <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                Saldo Pesos de Lealtad: ${Number(ticket.pesosLealtad.saldoPesosLealtad).toFixed(2)}
                                            </Typography>
                                            <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                Pesos Ganados este Mes: ${Number(ticket.pesosLealtad.pesosGanadosMes).toFixed(2)}
                                            </Typography>
                                            <br />
                                            {
                                                ticket.pesosLealtad.marcas.map((marca, index) => (
                                                    <div key={index}>
                                                        <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4, mt: 3 }}>
                                                            <Grid display="flex" justifyContent="center">
                                                                <Typography variant='h6' style={{ fontWeight: 'bold' }}>{marca.nombreProveedor}</Typography>
                                                            </Grid>

                                                            <Grid display="flex" justifyContent="space-between" alignItems="center">
                                                                <Grid>
                                                                    <img
                                                                        src={`${REACT_APP_API_URL_IMAGES}${marca.urlImagen}`}
                                                                        alt=""
                                                                        width="150" height="150"
                                                                    />
                                                                </Grid>
                                                                <Grid>
                                                                    <ListItem>
                                                                        <ListItemText 
                                                                            primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Compra Anterior</Typography>}
                                                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.compraAnterior).toFixed(2)}</Typography>}/>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <ListItemText 
                                                                            primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Tu Compra este Mes</Typography>}
                                                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.compraEsteMes).toFixed(2)}</Typography>} />
                                                                    </ListItem>
                                                                </Grid>
                                                                <Grid>
                                                                    <ListItem>
                                                                        <ListItemText 
                                                                            primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Objetivo de Compra</Typography>}
                                                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.objetivosCompra).toFixed(2)}</Typography>} />
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <ListItemText 
                                                                            primary={<Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>Pesos Generados</Typography>}
                                                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'right', color: '#feb02a' }}>${Number(marca.pesosGenerados).toFixed(2)}</Typography>} />
                                                                    </ListItem>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    )}
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
