import axios from 'axios';
import { to } from 'await-to-js';
import { logoutAuth } from '../auth/index';

const { REACT_APP_API_URL } = process.env;
// const token = localStorage.getItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO');
// const headers = {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Credentials": "true",
//     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
//     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
//     "Content-Type": "application/json",
//     "Authorization": "Bearer" + token,
//     "plataforma": "kiosko",
//     "x-access-token": token,
// }

export async function obtenerClienteNumeroCliente(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_numero_cliente`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteCodigo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_codigo`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteNombre(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_nombre`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteTelefono(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_telefono`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerClienteCorreo(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_cliente_correo`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function obtenerTicket(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/obtener_tickets_sucursal_cliente`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}

export async function registrarRegistro(payload) {
    const [error, response] = await to(axios.post(`${REACT_APP_API_URL}/administrador/tickets/registrar_registro`, payload));

    if (error) {
        if (error.response.status === 403) {
            logoutAuth();
            window.setTimeout(function() {
                window.location.href = "/"
            }, 3000);
    
            return {
                status: false,
                mensaje: error.response.data.mensaje,
            };
        }

        return {
            status: false,
            mensaje: 'Ocurrio un error al conectar con el servidor',
        };
    }

    return response.data;
}
