import React from "react";
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

import LoginForm from "./LoginForm";

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function Login() {

    return (
        <>
            <Helmet>
                <title>Ingresar | Scorpion Kiosko</title>
            </Helmet>
            
            <StyledRoot>
                <Container maxWidth="sm">
                    <StyledContent>

                        <Typography variant="h2" align="center" mt={5} mb={5} gutterBottom>
                            Bienvenido a Scorpion Tickets Kiosko
                        </Typography>

                        <LoginForm />
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}