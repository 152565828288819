import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
// hooks

const initialState = {
    cliente: {},
    onChangeCliente: () => {},
};

const handlers = {
    SETCLIENTE: (state, action) => {
        const { cliente } = action.payload;
        return {
            ...state,
            cliente
        }
    }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ClienteContext = createContext(initialState);

ClienteProvider.propTypes = {
    children: PropTypes.node,
};

function ClienteProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangeCliente = (cliente) => {
        dispatch({
            type: 'SETCLIENTE',
            payload: { cliente }
        });
    }

    return (
        <ClienteContext.Provider
            value={{
                ...state,
                onChangeCliente,
            }}
        >
            {children}
        </ClienteContext.Provider>
    );
}

export { ClienteProvider, ClienteContext };
