export function loginAuth(token, sucursal) {
    localStorage.setItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO', token);
    localStorage.setItem('SUCURSAL_SCORPION_TICKETS_KIOSKO', JSON.stringify(sucursal));
}

export function logoutAuth() {
    localStorage.removeItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO');
    localStorage.removeItem('SUCURSAL_SCORPION_TICKETS_KIOSKO');
    localStorage.clear();
}

export function isLogin() {
    if (localStorage.getItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO')) {
        return true;
    }

    return false;
}
