import { useEffect, useState } from 'react';
import { PDFViewer, Page, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import { useBarcode } from 'next-barcode';

import BarCode from './BarCode';

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '15',
    }
});


const PdfBarCode = ({ clientId }) => {
    const [imgUrl, setImageUrl] = useState(null);

    const { inputRef } = useBarcode({
        value: clientId
    });

    useEffect(() => {
        setImageUrl(inputRef?.current.src);
    }, []);


    return (
        <>
            <div style={{ display: 'none' }}>
                <BarCode codeRef={inputRef} />
                {
                    imgUrl && (
                        <PDFViewer className='pdf__viewer' id='pdfBarCode' >
                            <Document>
                                <Page size={[227, 792]} style={styles.page}>
                                    <View >
                                        <Image src={imgUrl} />
                                    </View>
                                </Page >
                            </Document >
                        </PDFViewer>
                    )
                }
            </div>
        </>
    )
}

export default PdfBarCode;