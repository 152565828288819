import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Box,
    Grid,
    Container,
    Typography,
    Card,
    Stack,
    TextField,
    Button,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { styled } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import { LoadingButton } from '@material-ui/lab';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// TECLADO
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import '../../assets/scss/teclado.css';

import { fPhoneNumber2 } from '../../utils/formatNumber';

// Pdf
import { PDFViewer } from '@react-pdf/renderer';
import PdfTicket from '../components/PdfTicket';


// Context
import useCliente from '../../hooks/useCliente';

// // Servicios
import {
    obtenerClienteNumeroCliente,
    obtenerClienteCodigo,
    obtenerClienteNombre,
    obtenerClienteTelefono,
    obtenerClienteCorreo,
    obtenerTicket,
    registrarRegistro
} from '../../services/tickets';

// Importacion de conector impresora
// const { ConectorPluginV3 } = require("../promociones/ConectorJavaScript");

// Imagen
import imagen from '../../assets/images/kiosko/DON_TONO_SENALANDO.png';
import imagen2 from '../../assets/images/kiosko/tira-icono.png';

// ----------------------------------------------------------------------

const LargeImgStyle = styled('img')({
    width: '250px',
    height: '250px',
    position: 'absolute',
    top: '135px',
});

const LargeImgStyleTicket = styled('img')({
    width: '100px',
    height: '100px',
    position: 'absolute',
    top: '320px'
});

export default function Home() {
    const token = localStorage.getItem('TOKEN_SUCURSAL_SCORPION_TICKETS_KIOSKO');
    const sucursal = JSON.parse(localStorage.getItem('SUCURSAL_SCORPION_TICKETS_KIOSKO'));
    const { onChangeCliente } = useCliente();
    const navigate = useNavigate();
    const [loadingBotonBuscar, setLoadingBotonBuscar] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [form, setForm] = useState({
        numeroCliente: "",
        codigo: "",
        nombre: "",
        telefono: "",
        correo: "",
    });
    const [animacionCarga, setAnimacionCarga] = useState(false);
    const [ticket, setTicket] = useState({});
    const [openTeclado, setOpenTeclado] = useState(false);
    const [campo, setCampo] = useState("");
    const [layoutName, setLayoutName] = useState("default");
    const [input, setInput] = useState('');
    const keyboard = useRef();
    let timeout = null;

    const goBackToHome = () => {
        window.location.reload();
    };

    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }

        // Establecer tiempo para recargar la página si hay inactividad en 20 segundos
        timeout = setTimeout(() => {
            goBackToHome();
        }, 20000);
    }
    
    const onMouseMove = () => {
        restartAutoReset();
    }
    
    useEffect(() => {
        // Significa que no esta vacia la tabla de clientes
        if (clientes.length !== 0) {
            // Iniciar tiempo
            restartAutoReset();
        
            // Escuchar mouse event
            window.addEventListener('mousemove', onMouseMove);
        
            // Limpiar
            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                    window.removeEventListener('mousemove', onMouseMove);
                }
            }
        }
    }, [clientes]);

    // Para buscar automaticamente cuando el campo de código de barras sea igual a 13 caracteres
    useEffect(() => {
        if (form.codigo.length === 13) {
            buscar();
        }
    }, [form]);

    const handleChangeText = (e) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    }

    const buscar = async () => {
        setOpenTeclado(false);
        setLoadingBotonBuscar(true);
        let result = {}

        if (form.numeroCliente !== "") {
            result = await obtenerClienteNumeroCliente({ numeroCliente: form.numeroCliente, headers:{"x-access-token": token, "plataforma": "kiosko"} });
        }
        else if (form.codigo !== "") {
            result = await obtenerClienteCodigo({ codigo: form.codigo, headers:{"x-access-token": token, "plataforma": "kiosko"} });
        }
        else if (form.nombre !== "") {
            // El campo de nombre que tenga por lo menos 2 palabras
            if (form.nombre.split(" ").length > 1) {
                result = await obtenerClienteNombre({ nombre: form.nombre, headers:{"x-access-token": token, "plataforma": "kiosko"} });
            } else {
                enqueueSnackbar('Por favor introduzca por lo menos dos palabras en el nombre', { 
                    variant: 'error',
                    autoHideDuration: 2500,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });

                setLoadingBotonBuscar(false);
                return;
            }
        }
        else if (form.telefono !== "") {
            result = await obtenerClienteTelefono({ telefono: form.telefono, headers:{"x-access-token": token, "plataforma": "kiosko"} });
        }
        else if (form.correo !== "") {
            result = await obtenerClienteCorreo({ correo: form.correo, headers:{"x-access-token": token, "plataforma": "kiosko"} });
        }
        else {
            enqueueSnackbar('Por favor introduzca datos en algun campo', { 
                variant: 'error',
                autoHideDuration: 2500,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });

            setLoadingBotonBuscar(false);
            return;
        }

        setForm({
            numeroCliente: "",
            codigo: "",
            nombre: "",
            telefono: "",
            correo: "",
        });
        setCampo("");
        keyboard.current.setInput("");
        setLoadingBotonBuscar(false);

        if (result.status) {
            setClientes(result.data);

            if (result.data.length === 1) {
                setAnimacionCarga(true);
                const resultTicket = await obtenerTicket({sucursal: sucursal.num_sucursal, cliente: result.data[0].fields.cte, numeroTarjeta: result.data[0].pk, nombreCliente: result.data[0].fields.nom});
                setAnimacionCarga(false);

                if (resultTicket.status) {
                    setTicket(resultTicket.data);
                    // Imprimir el ticket si es un solo registro
                    setTimeout(() => {
                        const iframe = window.frames.pdf;
                        const iframeWindow = iframe.contentWindow;

                        console.log(iframe);
                        console.log(iframeWindow);
                        setTimeout(() => {
                            iframe.focus();
                            iframeWindow.print();
                        }, 250);
                    }, 250);

                    // HACER REGISTRO EN LA BD
                    await registrarRegistro({
                        num_tarjeta: resultTicket.data.numeroTarjeta,
                        num_cliente: resultTicket.data.numeroCliente,
                        usuario: resultTicket.data.nombreCliente,
                        num_sucursal: sucursal.num_sucursal,
                        nombre_sucursal: resultTicket.data.sucursal 
                    });
                }
            }

            return;
        }

        enqueueSnackbar(result.mensaje, { 
            variant: 'error',
            autoHideDuration: 2500,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        });
    }

    const imprimirTira = (data) => {
        const dataCliente = data.fields;
        dataCliente.id = data.pk;
        onChangeCliente({});
        onChangeCliente(dataCliente);
        navigate('/app/promociones', { replace: true });
    }

    const pesosLealtad = (data) => {
        const dataCliente = data.fields;
        dataCliente.id = data.pk;
        onChangeCliente({});
        onChangeCliente(dataCliente);
        navigate('/app/pesos-lealtad', { replace: true });
    }

    const compraGana = (data) => {
        const dataCliente = data.fields;
        dataCliente.id = data.pk;
        onChangeCliente({});
        onChangeCliente(dataCliente);
        navigate('/app/compra-gana', { replace: true });
    }

    const imprimirCodigoBarras = (data) => {
        const dataCliente = data.fields;
        dataCliente.id = data.pk;
        onChangeCliente({});
        onChangeCliente(dataCliente);
        navigate('/app/codigo-barras', { replace: true });
    }

    const activarTeclado = (nombreCampo) => {
        if (campo !== "") {
            if (campo !== nombreCampo) {
                setForm({
                    numeroCliente: "",
                    codigo: "",
                    nombre: "",
                    telefono: "",
                    correo: "",
                });
                keyboard.current.setInput("");
            }
        }

        setCampo(nombreCampo);
        setOpenTeclado(true);
    }

    // TECLADO
    const handleShift = () => {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    };

    // TECLADO
    const onChange = (input) => {
        setInput(input);
        setForm({
            ...form,
            [campo]: input,
        });
    }

    // TECLADO
    const onKeyPress = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }

        if (button === "{enter}") {
            setOpenTeclado(false);
            setCampo("");
        }
    }

    return (
        <div>
            <Helmet>
                <title> Inicio | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            {/* Animación de Carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={animacionCarga}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container maxWidth="xl" style={{ marginTop: '30px' }}>
                <Box sx={{ display: 'flex', marginBottom: '15px', alignItems: 'baseline' }}>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>Imprime tu&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px' }}>TIRA&nbsp;</h1>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>de&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px', fontStyle: 'italic' }}>promociones</h1>
                </Box>
                <hr style={{ borderTop: '8px solid #fff', borderRadius: '5px', background: 'white', opacity: 'inherit' }}></hr>

                <Grid container style={{ writingMode: 'vertical-rl', display: 'block', marginInline: 'auto', maxWidth: '100%' }}>
                    <Box>
                        <LargeImgStyle alt="Don Toño" src={imagen} />
                    </Box>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Grid item xs={12} md={12} style={{ marginTop: '20px', marginBottom: '30px', border: 'solid #fff', borderRadius: '20px', padding: '10px' }}>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4 }}>
                                {
                                    Object.keys(ticket).length !== 0 && (
                                        <PDFViewer className='pdf__viewer' id='pdf' style={{ display: "none" }}>
                                            <PdfTicket
                                                ticketData={ticket}
                                            />
                                        </PDFViewer>
                                    )
                                }

                                <Grid item style={{ display: 'block', marginInline: 'auto', maxWidth: '100%' }}>
                                    <LargeImgStyleTicket alt="Ticket" src={imagen2} />
                                </Grid>

                                <Box sx={{ display: 'flex', marginBottom: '10px', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                    <h2 style={{ color: '#000', fontSize: '35px' }}>Encuentra ofertas personalizadas&nbsp;</h2>
                                    <h1 style={{ color: '#feb02a', fontSize: '65px' }}>¡PARA TI!</h1>
                                </Box>
                                <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold', fontSize: 'large' }}>
                                    Ingresa alguno de los siguientes datos para imprimir tu tira de promociones, después oprime el botón de buscar. 
                                </Typography>

                                <Stack spacing={3}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                        <TextField
                                            id="codigo"
                                            name="codigo"
                                            label="Código de Barras"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            autoFocus
                                            onFocus={() => activarTeclado("codigo")}
                                            InputLabelProps={{style: {fontSize: 20}}}
                                            value={form.codigo}
                                            onChange={handleChangeText}
                                        />
                                        <TextField
                                            id="numeroCliente"
                                            name="numeroCliente"
                                            label="Número de Cliente"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            onFocus={() => activarTeclado("numeroCliente")}
                                            InputLabelProps={{style: {fontSize: 20}}}
                                            value={form.numeroCliente}
                                            onChange={handleChangeText}
                                        />
                                        <TextField
                                            id="nombre"
                                            name="nombre"
                                            label="Nombre de Cliente"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            onFocus={() => activarTeclado("nombre")}
                                            InputLabelProps={{style: {fontSize: 20}}}
                                            value={form.nombre}
                                            onChange={handleChangeText}
                                        />
                                        <TextField
                                            id="telefono"
                                            name="telefono"
                                            label="Teléfono"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            onFocus={() => activarTeclado("telefono")}
                                            InputLabelProps={{style: {fontSize: 20}}}
                                            value={form.telefono}
                                            onChange={handleChangeText}
                                        />
                                        <TextField
                                            id="correo"
                                            name="correo"
                                            label="Correo"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete='off'
                                            onFocus={() => activarTeclado("correo")}
                                            InputLabelProps={{style: {fontSize: 20}}}
                                            value={form.correo}
                                            onChange={handleChangeText}
                                        />
                                    </Stack>

                                    <Box sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center' }}>
                                        <LoadingButton variant="contained" loading={loadingBotonBuscar} onClick={buscar} style={{ background: '#ea5317' }}>
                                            Buscar
                                        </LoadingButton>
                                    </Box>
                                </Stack>
                            </Card>
                        </Grid>

                        <div style={{ visibility: openTeclado ? "" : "hidden", height: openTeclado ? "" : 0 }}>
                            <Keyboard
                                keyboardRef={(r) => (keyboard.current = r)}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                layoutName={layoutName}
                                display={{
                                    "{tab}": "Tab",
                                    "{lock}": "Mayús",
                                    "{shift}": "Shift",
                                    "{bksp}": "Borrar",
                                    "{enter}": "Enter",
                                    "{space}": "Espacio"
                                }}
                            />
                        </div>

                        {
                            clientes.length > 0 && (
                                <Card sx={{ p: 3, mt: 3, mb: 5, boxShadow: 18, borderRadius: 4 }}>
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Número de Cliente</th>
                                                    <th>Nombre Cliente</th>
                                                    <th>Teléfono</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    clientes.map((cliente) => {
                                                        const { cte, nom, tel1 } = cliente.fields;

                                                        return (
                                                            <tr style={{ fontWeight: 'normal', verticalAlign: 'baseline' }}>
                                                                <td><Typography variant='body1'>{cliente.pk}</Typography></td>
                                                                <td><Typography variant='body1'>{cte}</Typography></td>
                                                                <td><Typography variant='body1'>{nom}</Typography></td>
                                                                <td><Typography variant='body1'>{fPhoneNumber2(tel1)}</Typography></td>
                                                                <td style={{ textAlign: 'end' }}>
                                                                    <Button variant='contained' onClick={() => imprimirTira(cliente)} style={{ background: '#ea5317', marginRight: '10px' }}>
                                                                        Imprimir Tira
                                                                    </Button>
                                                                    {/* <Button variant='contained' onClick={() => pesosLealtad(cliente)} style={{ background: '#ea5317', marginRight: '10px' }}>
                                                                        Pesos Lealtad
                                                                    </Button>
                                                                    <Button variant='contained' onClick={() => compraGana(cliente)} style={{ background: '#ea5317', marginRight: '10px' }}>
                                                                        Compra y Gana
                                                                    </Button>  */}
                                                                    <Button variant='contained' onClick={() => imprimirCodigoBarras(cliente)} style={{ background: '#ea5317' }}>
                                                                        Imprimir Codigo de Barras
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            )
                        }
                    </Grid> 
                </Grid>
            </Container>
        </div>
    );
}
