import { Outlet, Link } from 'react-router-dom';
// @mui
import {
    Grid,
    Container,
    Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------
import logo from '../../assets/images/logos/logo_scorpion.png';
import fondo from '../../assets/images/kiosko/fondo.png';

const StyledRoot = styled('div')({
    display: 'flex',
    overflow: 'hidden',
    backgroundImage: `url(${fondo})`,
    minHeight: '100vh',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

export default function AdministradorLayout() {

    return (
        <StyledRoot>

            <Container maxWidth="xl">
                <Grid container mt={1} style={{ justifyContent: 'center' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }}>
                        <Link to="/app/inicio">
                            <img src={logo} alt="scorpion" height="140" />
                        </Link>
                    </Stack>
                </Grid>
                <Main>
                    <Outlet />
                </Main>
            </Container>
        </StyledRoot>
    );
}
