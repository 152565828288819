import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Card,
    Stack,
    Typography
} from '@mui/material';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { fPhoneNumber2 } from '../../utils/formatNumber';

// context
import useCliente from '../../hooks/useCliente';

// ----------------------------------------------------------------------

export default function MiCuenta() {
    const { cliente, onChangeCliente } = useCliente();
    const navigate = useNavigate();

    useEffect(() => {
        // Llevar a la página principal después de 20 segundos
        setTimeout(()=> {
            onChangeCliente({});
            navigate('/app/inicio', { replace: true });
        }, 20000);
    }, []);

    return (
        <div>
            <Helmet>
                <title> Mi Cuenta | Scorpion </title>
            </Helmet>

            <Container maxWidth="xl">
                <Breadcrumb className='px-3 py-2 bg-light rounded mb-3' style={{ marginTop: '100px' }}>
                    <BreadcrumbItem><Link to="/app/inicio">Inicio</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/app/opciones">Opciones</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Mi Cuenta</BreadcrumbItem>
                </Breadcrumb>
                <Grid container spacing={3} mb={3} mt={3} style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 3, mb: 5, boxShadow: 18, borderRadius: 4 }}>
                            <Typography variant="h4" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                                Mi Cuenta
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 5, textAlign: 'center' }}>
                                Esta es tu información
                            </Typography>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                                        Nombre:
                                    </Typography>
                                    <Typography variant='h6'>
                                        {cliente.nom}
                                    </Typography>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                                        Tarjeta:
                                    </Typography>
                                    <Typography variant='h6'>
                                        {cliente.id}
                                    </Typography>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                                        Número de Cliente:
                                    </Typography>
                                    <Typography variant='h6'>
                                        {cliente.cte}
                                    </Typography>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                                        Teléfono:
                                    </Typography>
                                    <Typography variant='h6'>
                                        {fPhoneNumber2(cliente.tel1)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid> 
                </Grid>
            </Container>
        </div>
    );
}
