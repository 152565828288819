import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography
} from '@mui/material';
import { Row, Col, Card, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

// ----------------------------------------------------------------------

export default function Opciones() {
    const navigate = useNavigate();

    useEffect(() => {
        // Llevar a la página principal después de 30 segundos
        setTimeout(()=> {
            navigate('/app/inicio', { replace: true });
        }, 30000);
    }, []);

    const miCuenta = () => {
        navigate('/app/mi-cuenta', { replace: true });
    }

    const promocionesTicket = () => {
        navigate('/app/promociones', { replace: true });
    }

    const codigoBarras = () => {
        navigate('/app/codigo-barras', { replace: true });
    }

    return (
        <div>
            <Helmet>
                <title> Opciones | Scorpion </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3} mb={3} mt={7}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumb className='px-3 py-2 bg-light rounded mb-3'>
                            <BreadcrumbItem><Link to="/app/inicio">Inicio</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Opciones</BreadcrumbItem>
                        </Breadcrumb>
                        <Typography variant='h4' style={{ color: 'white' }}>
                            Consulta:
                        </Typography>

                        <div className="blog-home2 spacer">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <Card style={{ padding: '30px' }} onClick={miCuenta}>
                                        <div className="date-pos bg-info-gradiant"><PersonIcon /></div>
                                        <h2 className="font-medium m-t-30">Mi Cuenta</h2>
                                        <Link to="/app/mi-cuenta" className="font-medium m-t-40 linking text-themecolor m-t-10" style={{ alignSelf: 'end' }}>Ver Cuenta<i className="ti-arrow-right"></i></Link>
                                    </Card>
                                </Col>
                                <Col lg="5" md="6">
                                    <Card style={{ padding: '30px' }} onClick={promocionesTicket}>
                                        <div className="date-pos bg-info-gradiant"><ReceiptIcon /></div>
                                        <h2 className="font-medium m-t-30">Imprime tu tira de promociones</h2>
                                        <Link to="/app/promociones" className="font-medium m-t-40 linking text-themecolor m-t-10" style={{ alignSelf: 'end' }}>Imprimir<i className="ti-arrow-right"></i></Link>
                                    </Card>
                                </Col>
                            
                            </Row>
                            <Row className="m-t-10 justify-content-center">
                                <Col lg="5" md="6">
                                    <Card style={{ padding: '30px' }} onClick={codigoBarras}>
                                        <div className="date-pos bg-info-gradiant"><ViewWeekIcon /></div>
                                        <h2 className="font-medium m-t-30">Imprime tu código de barras</h2>
                                        <Link to="/app/codigo-barras" className="font-medium m-t-40 linking text-themecolor m-t-10" style={{ alignSelf: 'end' }}>Imprimir<i className="ti-arrow-right"></i></Link>
                                    </Card>
                                </Col>
                                {/* <Col lg="5" md="6">
                                    <Card style={{ padding: '30px' }}>
                                        <div className="date-pos bg-info-gradiant">Oct<span>23</span></div>
                                        <h2 className="font-medium m-t-30">Club del Comerciante</h2>
                                        <a href="#" style={{ alignSelf: 'self-end' }} className="font-medium m-t-40 linking text-themecolor m-t-10">Ver<i className="ti-arrow-right"></i></a>
                                    </Card>
                                </Col> */}
                            </Row> 
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
