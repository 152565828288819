import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Box,
    Card,
    Stack,
    Typography,
} from '@mui/material';
import { styled } from '@material-ui/core/styles';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@material-ui/lab';

// Pdf
// import { PDFViewer } from '@react-pdf/renderer';
// import PdfTicket from '../components/PdfTicket';
import PdfBarCode from '../components/PdfBarCode';

// context
import useCliente from '../../hooks/useCliente';

// Importacion de conector impresora
// const { ConectorPluginV3 } = require("../promociones/ConectorJavaScript");

// ----------------------------------------------------------------------

import imagen from '../../assets/images/kiosko/Don Tono Portal.png';

const LargeImgStyle = styled('img')({
    width: '400px',
    height: '400px',
    position: 'absolute',
    marginTop: '50px'
});

export default function CodigoBarras() {
    const { cliente, onChangeCliente } = useCliente();
    const navigate = useNavigate();
    let timeout = null;

    const goBackToHome = () => {
        navigate('/app/inicio', { replace: true });
    };

    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }

        // Establecer tiempo para recargar la página si hay inactividad en 5 segundos
        timeout = setTimeout(() => {
            goBackToHome();
        }, 5000);
    }
    
    const onMouseMove = () => {
        restartAutoReset();
    }
    
    useEffect(() => {
        // Iniciar tiempo
        restartAutoReset();
    
        // Escuchar mouse event
        window.addEventListener('mousemove', onMouseMove);
    
        // Limpiar
        return () => {
            if (timeout) {
                clearTimeout(timeout);
                window.removeEventListener('mousemove', onMouseMove);
            }
        }
    }, []);

    const imprimirTicket = async () => {
        const iframe = window.frames.pdfBarCode;
        const iframeWindow = iframe.contentWindow;

        console.log(iframe);
        console.log(iframeWindow);
        setTimeout(() => {
            iframe.focus();
            iframeWindow.print();
        }, 500);

        setTimeout(()=> {
           onChangeCliente({});
           navigate('/app/inicio', { replace: true });
        }, 20000);
    }

    return (
        <div>
            <Helmet>
                <title> Código Barras | Scorpion </title>
            </Helmet>

            <Container maxWidth="xl" style={{ marginTop: '50px' }}>
                <Box sx={{ display: 'flex', marginBottom: '25px', alignItems: 'baseline' }}>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>Imprime tu&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px' }}>TIRA&nbsp;</h1>
                    <h2 style={{ color: 'white', fontSize: '40px' }}>de&nbsp;</h2>
                    <h1 style={{ color: '#feb02a', fontSize: '70px', fontStyle: 'italic' }}>Código de Barras</h1>
                </Box>
                <hr style={{ borderTop: '8px solid #fff', borderRadius: '5px', background: 'white', opacity: 'inherit' }}></hr>

                <Box sx={{ display: 'flex', alignItems: 'end', marginTop: '30px' }}>
                    <Breadcrumb className='px-3 py-2 bg-light rounded mb-3' style={{ width: '-webkit-fill-available', marginRight: '25px' }}>
                        <BreadcrumbItem><Link to="/app/inicio">Inicio</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Código de Barras</BreadcrumbItem>
                    </Breadcrumb>
                    <LoadingButton variant="contained" style={{ background: '#ea5317', marginBottom: '25px' }} startIcon={<PrintIcon />} onClick={imprimirTicket}>
                        Imprimir
                    </LoadingButton>
                </Box>

                <Grid item style={{ display: 'block', marginInline: 'auto', maxWidth: '100%' }}>
                    <Box>
                        <LargeImgStyle alt="Don Toño" src={imagen} />
                    </Box>
                </Grid>

                <Grid container spacing={3} mb={3} mt={1} style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        {Object.keys(cliente).length !== 0 && (
                            <PdfBarCode clientId={cliente.id} />
                        )
                        }
                        <Grid item xs={12} style={{ border: 'solid #fff', borderRadius: '20px', padding: '10px' }}>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4 }}>
                                <Typography variant="h4" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>
                                    Código de Barras
                                </Typography>
                                <Stack>
                                    <Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        CÓDIGO DE BARRAS: {cliente.id}
                                    </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid> 
                </Grid>
            </Container>
        </div>
    );
}
