import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CompraGanaIcon() {
  return (
    <SvgIcon style={{ height: '150px', width: '150px'}}>
        <svg version="1.1" viewBox="0 0 1184 1184" width="1280" height="1280" xmlns="http://www.w3.org/2000/svg">
            <path transform="translate(147,564)" d="m0 0h145l18 1 11 3 16 8 8 7 7 10 5 13 3 16v5h499l8 2 7 6 2 4 1 10-2 15-16 62-14 51-7 26-9 31-5 12-9 12-11 8-9 4-9 2h-366l6 19 10 35v2h355l10 2 9 6 6 7 4 9 1 12-3 10-6 9-8 6-9 3-81 1-1 1 14 7 10 9 7 10 5 13 2 13-1 11-4 13-6 11-6 7h-2v2l-10 7-12 5-10 2h-14l-10-2-10-4-13-10-8-10-6-13-3-14 1-14 4-12 5-10 10-11 11-7 6-3-1-1h-190l-2 1 14 7 11 10 7 11 4 11 2 10-1 14-4 13-5 9-7 9-12 9-12 5-10 2h-15l-12-3-13-7-7-6-9-12-5-11-2-8v-19l4-13 4-8 7-9 8-7 14-7-2-1-98-1-9-4-6-5-6-9-3-8v-11l4-11 9-10 10-5 4-1h26l-1-8-20-74-14-51-8-30-19-70-17-62v-4l-15 1h-24l-114-1-11-4-8-7-7-14-1-13 2-8 4-7 6-5 8-4 7-2zm296 125-1 1-1 61v61l38 1v-124zm94 0-3 2v121l38 1v-124zm92 0-2 6v117l36 1 2-1v-123zm93 0-1 1-1 33v89l35 1 3-1v-123zm-266 328-10 3-6 4-6 8-3 7-1 12 3 10 6 8 8 6 6 2h15l9-4 8-7 4-7 2-7v-10l-5-12-5-6-9-5-7-2zm233 0-10 3-9 7-6 12-1 13 4 11 9 10 10 4h15l9-4 8-7 5-10 1-9-2-11-5-8-8-7-6-3-5-1z" fill="#fff"/>
            <path transform="translate(414,438)" d="m0 0h208l9 2 8 6 7 9 2 12v145l-7 2h-251l-1-1v-147l2-9 6-10 7-6z" fill="#fff"/>
            <path transform="translate(224,188)" d="m0 0 19 1 19 3 18 5 18 8 13 7 17 12 10 9 12 12 11 15 10 17 8 20 5 17 3 20v32l-4 22-5 16-7 17-8 14-10 14-9 10-4 5-8 7-16 12-14 8-17 8-19 6-15 3-8 1h-33l-19-3-20-6-18-8-14-8-12-9-13-11-11-12-10-14-9-15-8-18-6-21-3-17-1-9v-16l3-24 5-19 6-15 8-16 10-15 9-11 8-9 11-9 13-10 18-10 20-8 15-4 17-3zm-4 27-21 3-15 4-19 8-16 10-14 12-5 5-9 10-10 15-9 19-6 19-3 21v16l3 21 6 19 6 14 10 16 9 11 14 14 14 10 16 9 16 6 16 4 8 1h33l15-3 16-5 19-9 12-8 11-9 13-13 10-14 8-14 7-19 4-16 1-7v-31l-4-20-5-15-8-17-8-12-9-11-11-11-13-10-16-9-20-8-16-4-16-2z" fill="#fff"/>
            <path transform="translate(673,503)" d="m0 0h130l9 2 7 8 1 2 1 97-1 1-21 1h-136l-2-1v-96l4-8 6-5z" fill="#fff"/>
            <path transform="translate(187,243)" d="m0 0h9l5 5 4 10 1 4 7-1h19l10 3 9 5 6 5 5 8 1 10-3 6-8 7-7 1-10-4-9-6-7-2 10 30 2 4 30 1 12 3 9 5 8 7 7 11 4 11 2 12v7l-4 15-6 10-6 7-13 9-6 3 4 13v8l-5 5-8 1-5-2-7-14v-3l-8 2-16 1-13-2-12-5-9-7-5-8-1-3v-9l3-6 6-5 4-2h8l8 4 9 7 13 1-10-29-4-9h-24l-12-2-14-7-9-9-7-14-3-13v-12l3-11 7-12 10-9 14-8-4-12v-10zm8 58-6 5-3 7v6l5 8 5 3 5 1h7l-10-30zm49 64 3 11 8 21 5-2v-2h2l3-6v-10l-3-6-5-4-9-2z" fill="#fff"/>
            <path transform="translate(981,408)" d="m0 0h22l15 2 17 5 19 9 12 8 14 12 8 9 7 10 8 14 7 19 3 14 2 22-2 18-4 17-6 16-10 17-9 11-13 13-14 10-15 8-18 6-13 3-8 1h-23l-17-3-21-7-15-8-11-8-12-11-9-10-9-14-9-19-5-18-2-12v-22l3-19 6-18 11-21 8-10 9-10 11-10 14-9 17-8 13-4 9-2zm-2 21-17 4-13 5-14 8-10 8-9 8-11 16-9 19-4 15-1 9v19l3 16 6 16 8 15 9 11 10 10 16 11 16 7 16 4 7 1h20l17-3 16-6 14-8 14-11 9-10 9-13 7-16 4-14 1-6v-28l-4-17-7-17-9-14-11-12-10-8-13-8-16-7-18-4z" fill="#fff"/>
            <path transform="translate(573,373)" d="m0 0h91l15 2 8 5 7 8 3 8 1 18v74l-1 1-23 2-6 4-5 10h-2l-1-53-4-12-6-7-10-5-6-1-87-1-2-1v-29l3-8 8-9 8-4z" fill="#fff"/>
            <path transform="translate(1019,451)" d="m0 0 9 1 4 4-1 6-3 6 1 5 9 7 6 8 3 7 1 5v8l-4 8-4 3-6 1-7-2-4-3-5-18-2-1-2 6-9 20 4 2 12 11 6 10 2 5 1 12-3 11-6 11-8 8-10 5-12 2-19-3-2 7-5 6-5 1-5-3-2-7 1-5 2-7-4-2-8-6-7-8-5-12-1-10 3-8 4-4 5-2 8 1 5 4 3 7 2 9 4 6 3 1 3-9 7-16-1-4-10-9-7-10-4-9v-14l4-11 6-9 8-7 9-4 5-1h9l10 2h4l6-11zm-27 36-5 3-3 6 1 6 4 6h3l4-8 4-10v-3zm1 62-9 18v4h9l6-7 1-4-2-6-3-5z" fill="#fff"/>
            <path transform="translate(604,76)" d="m0 0h13l21 1 1 5v90l-1 3h-34l-1-2v-94z" fill="#fff"/>
            <path transform="translate(838,125)" d="m0 0 5 1 25 14-1 6-10 17-6 11-15 26-14 24-1 2-5-1-18-10-7-4 1-6 14-25 10-17 12-21z" fill="#fff"/>
            <path transform="translate(400,124)" d="m0 0 5 1 11 20 10 17 15 26 10 18-1 4-24 14-5 1-14-25-14-24-12-21-8-13 1-3 22-12z" fill="#fff"/>
            <path transform="translate(1019,286)" d="m0 0 3 1 7 14 8 13-1 3-26 15-28 16-24 14-3 2-4-1-5-8-10-17 1-5 16-9 24-14 26-15z" fill="#fff"/>
        </svg>
    </SvgIcon>
  );
}
